import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex w-full justify-between px-4 text-[16px] font-semibold leading-[20px]" }
const _hoisted_2 = { class: "mt-[8px] grid w-full max-w-full gap-[4px]" }
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "p-2 px-4 w-full" }
const _hoisted_6 = { class: "flex justify-between w-full" }
const _hoisted_7 = { class: "font-semibold text-[16px] leading-[20px]" }

import anime from 'animejs';
import { sum, sumBy, uniqueId } from 'lodash';
import { computed, nextTick, onBeforeUpdate, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import TokenWeightInput from '@/components/inputs/TokenInput/TokenWeightInput.vue';
import usePoolCreation, {
  PoolSeedToken,
} from '@/composables/pools/usePoolCreation';
import useBreakpoints from '@/composables/useBreakpoints';
import useDarkMode from '@/composables/useDarkMode';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';


export default _defineComponent({
  emits: ['update:height', 'trigger:alert'],
  setup(__props, { emit }) {



const emptyTokenWeight: PoolSeedToken = {
  tokenAddress: '',
  weight: 0,
  id: '0',
  isLocked: false,
  amount: '0',
};

/**
 * COMPOSABLES
 */
const {
  updateTokenWeights,
  proceed,
  acceptCustomTokenDisclaimer,
  setTokensList,
  seedTokens,
  tokensList,
  totalLiquidity,
  hasInjectedToken,
  acceptedCustomTokenDisclaimer,
} = usePoolCreation();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2 } = useNumbers();
const { nativeAsset, getToken } = useTokens();
const { isWalletReady, startConnectWithInjectedProvider } = useWeb3();
const { t } = useI18n();
const { darkMode } = useDarkMode();

/**
 * STATE
 */
const networkName = configService.network.name;

const tokenWeightListWrapper = ref<HTMLElement>();
const addTokenRowElement = ref<HTMLElement>();
const totalsRowElement = ref<HTMLElement>();
const seedTokenElements = ref<HTMLElement[]>([]);
const cardWrapper = ref<HTMLElement>();
const wrapperHeight = ref(0);
const cardWrapperHeight = ref(0);

/**
 * COMPUTED
 */
const tokenWeightItemHeight = computed(() =>
  upToLargeBreakpoint.value ? 56 : 64
);

const zeroWeightToken = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  const zeroWeightToken = validTokens.find(t => t.weight === 0);
  if (zeroWeightToken) {
    return getToken(zeroWeightToken.tokenAddress);
  }
  return null;
});

const walletLabel = computed(() => {
  if (!isWalletReady.value) {
    return t('connectWallet');
  }
  if (showLiquidityAlert.value) {
    return t('continueAnyway');
  }
  return t('next');
});

const totalAllocatedWeight = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  const validPercentage = sumBy(validTokens, 'weight');
  return validPercentage.toFixed(2);
});

const totalWeight = computed(() => {
  const pct = sumBy(seedTokens.value, 'weight');
  return pct.toFixed(2);
});

const isProceedDisabled = computed(() => {
  if (!isWalletReady.value) return false;
  if (Number(totalAllocatedWeight.value) !== 100) return true;
  if (seedTokens.value.length < 2) return true;
  if (zeroWeightToken.value) return true;
  if (hasInjectedToken.value && !acceptedCustomTokenDisclaimer.value)
    return true;
  return false;
});

const showLiquidityAlert = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  return totalLiquidity.value.lt(20000) && validTokens.length >= 2;
});

const excludedTokens = computed((): string[] => {
  return [
    nativeAsset.address,
    ...tokensList.value,
    '0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    '0x07f9f7f963c5cd2bbffd30ccfb964be114332e30',
  ];
});

const maxTokenAmountReached = computed(() => {
  return seedTokens.value.length >= 8;
});

const progressBarColor = computed(() => {
  if (
    Number(totalAllocatedWeight.value) > 100 ||
    Number(totalAllocatedWeight.value) <= 0
  ) {
    return 'red-400';
  }
  return 'green-400';
});

const weightColor = computed(() => {
  if (Number(totalWeight.value) > 100 || Number(totalWeight.value) <= 0) {
    return 'text-red-500';
  }
  return darkMode.value ? 'text-pink-third' : 'text-pink-third';
});

/**
 * WATCHERS
 */
watch(
  () => seedTokens,
  () => {
    setTokensList(seedTokens.value.map(w => w.tokenAddress));
  },
  {
    deep: true,
  }
);

/**
 * LIFECYCLE
 */
onMounted(async () => {
  // retrieving height causes reflow, get the height of the wrapper once
  // and manually uptick it when we add items to prevent double reflow during anim
  wrapperHeight.value = tokenWeightListWrapper.value?.offsetHeight || 0;
  cardWrapperHeight.value = cardWrapper.value?.offsetHeight || 0;

  // add in the first token list item
  if (!seedTokens.value.length) {
    addTokenToPool();
    addTokenToPool();
  } else {
    await animateHeight(seedTokens.value.length);
  }
  // wait for vue to reflect the changes of above
  await nextTick();
  distributeWeights();
});

onBeforeUpdate(() => {
  seedTokenElements.value = [];
});

/**
 * FUNCTIONS
 */
function handleWeightChange(weight: string, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.weight = Number(weight);

  distributeWeights();
}

function handleAddressChange(address: string, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.tokenAddress = address;
}

function handleLockedWeight(isLocked: boolean, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.isLocked = isLocked;
  distributeWeights();
}

async function animateHeight(offset = 0) {
  // animate the height initially
  emit('update:height', {
    height:
      (cardWrapper.value?.offsetHeight || 0) +
      tokenWeightItemHeight.value * offset,
  });
  anime({
    targets: tokenWeightListWrapper.value,
    height: `${wrapperHeight.value + tokenWeightItemHeight.value * offset}px`,
    complete: () => {
      emit('update:height', {
        height: cardWrapper.value?.offsetHeight || 0,
      });
    },
  });
  wrapperHeight.value += tokenWeightItemHeight.value * offset;
  // to avoid reflow we are going to transform the totals + add token
  // down instead of having the new token weight item shift them
  anime({
    targets: [totalsRowElement.value, addTokenRowElement.value],
    translateY: `${tokenWeightItemHeight.value * seedTokens.value.length}px`,
    easing: 'spring(0.4, 500, 9, 0)',
  });
  await nextTick();
  // get the last added token weight element
  seedTokenElements.value.forEach((seedTokenElement, i) => {
    anime.set(seedTokenElement, {
      left: 0,
      right: 0,
      top: `${tokenWeightItemHeight.value * i}px`,
    });
  });
}

async function addTokenToPool() {
  const newWeights: PoolSeedToken[] = [
    ...seedTokens.value,
    { ...emptyTokenWeight, id: uniqueId() } as PoolSeedToken,
  ];
  updateTokenWeights(newWeights);
  await animateHeight(1);
  distributeWeights();
}

function distributeWeights() {
  // get all the locked weights and sum those bad boys
  let lockedPct = sum(
    seedTokens.value.filter(w => w.isLocked).map(w => w.weight / 100)
  );
  // makes it so that new allocations are set as 0
  if (lockedPct > 1) lockedPct = 1;
  const pctAvailableToDistribute = bnum(1).minus(lockedPct);
  const unlockedWeights = seedTokens.value.filter(w => !w.isLocked);
  const evenDistributionWeight = pctAvailableToDistribute.div(
    unlockedWeights.length
  );

  const error = pctAvailableToDistribute.minus(
    evenDistributionWeight.times(unlockedWeights.length)
  );
  const isErrorDivisible = error.mod(unlockedWeights.length).eq(0);
  const distributableError = isErrorDivisible
    ? error.div(unlockedWeights.length)
    : error;

  const normalisedWeights = unlockedWeights.map((_, i) => {
    const evenDistributionWeight4DP = Number(evenDistributionWeight.toFixed(4));
    const errorScaledTo4DP = Number(distributableError.toString()) * 1e14;
    if (!isErrorDivisible && i === 0) {
      return evenDistributionWeight4DP + errorScaledTo4DP;
    } else if (isErrorDivisible) {
      return evenDistributionWeight4DP + errorScaledTo4DP;
    } else {
      return evenDistributionWeight4DP;
    }
  });

  unlockedWeights.forEach((tokenWeight, i) => {
    tokenWeight.weight = Number((normalisedWeights[i] * 100).toFixed(2));
  });
}

function addTokenListElementRef(el: HTMLElement) {
  // const filteredElements = seedTokenElements.value.filter(e => e !== null);
  if (!seedTokenElements.value.includes(el) && el) {
    seedTokenElements.value.push(el);
  }
}

async function handleRemoveToken(index: number) {
  updateTokenWeights(seedTokens.value.filter((_, i) => i !== index));
  await nextTick();
  seedTokenElements.value = seedTokenElements.value.filter(
    (_, i) => i !== index
  );
  distributeWeights();
  animateHeight(-1);
}

function handleProceed() {
  if (!isWalletReady.value) {
    startConnectWithInjectedProvider();
  } else {
    proceed();
  }
}

function onAlertMountChange() {
  emit('update:height', {
    height: cardWrapper.value?.offsetHeight || 0,
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalProgressBar = _resolveComponent("BalProgressBar")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", {
    ref: cardWrapper,
    class: "mb-16"
  }, [
    _createVNode(_component_BalStack, {
      vertical: "",
      spacing: "sm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('token')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('weight')), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(seedTokens), (token, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `tokenweight-${token.id}`,
                ref: addTokenListElementRef,
                class: "w-full max-w-full"
              }, [
                _createVNode(AnimatePresence, { isVisible: "" }, {
                  default: _withCtx(() => [
                    _createVNode(TokenWeightInput, {
                      weight: _unref(seedTokens)[i].weight,
                      "onUpdate:weight": [($event: any) => (_unref(seedTokens)[i].weight = $event), data => handleWeightChange(data, i)],
                      address: _unref(seedTokens)[i].tokenAddress,
                      "onUpdate:address": [($event: any) => (_unref(seedTokens)[i].tokenAddress = $event), data => handleAddressChange(data, i)],
                      noRules: "",
                      noMax: "",
                      class: "w-full",
                      excludedTokens: _unref(excludedTokens),
                      "onUpdate:isLocked": data => handleLockedWeight(data, i),
                      onDelete: () => handleRemoveToken(i)
                    }, null, 8, ["weight", "onUpdate:weight", "address", "onUpdate:address", "excludedTokens", "onUpdate:isLocked", "onDelete"])
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "text-pink-third leading-[18px] text-[14px] font-medium disabled:cursor-not-allowed py-[9.5px] px-[25px] bg-none border-[0.7px] border-[rgba(245,225,255,0.1)] rounded-2xl disabled:bg-red-600",
              disabled: _unref(maxTokenAmountReached),
              onClick: addTokenToPool
            }, _toDisplayString(_ctx.$t('addToken')), 9, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('totalAllocated')), 1),
              _createVNode(_component_BalStack, {
                horizontal: "",
                spacing: "xs",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["font-semibold text-[16px] leading-[20px]", _unref(weightColor)])
                  }, _toDisplayString(_unref(totalAllocatedWeight)) + "%", 3),
                  (Number(_unref(totalWeight)) > 100 || Number(_unref(totalWeight)) <= 0)
                    ? (_openBlock(), _createBlock(_component_BalIcon, {
                        key: 0,
                        class: "mt-px text-red-500",
                        name: "alert-circle",
                        size: "sm"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_BalProgressBar, {
              color: _unref(progressBarColor),
              width: _unref(totalAllocatedWeight),
              bufferWidth: 0,
              size: '[3px]',
              class: "my-2"
            }, null, 8, ["color", "width"])
          ])
        ]),
        _createVNode(AnimatePresence, {
          isVisible: _unref(showLiquidityAlert) && _unref(isWalletReady),
          unmountInstantly: "",
          onOnPresence: onAlertMountChange,
          onOnExit: onAlertMountChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalAlert, {
              title: _ctx.$t('createAPool.recommendedLiquidity'),
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('createAPool.youCanFundWithThisPoolWith', [
              _unref(fNum2)(_unref(totalLiquidity).toString(), _unref(FNumFormats).fiat),
            ])), 1)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: _unref(zeroWeightToken),
          unmountInstantly: "",
          onOnPresence: onAlertMountChange,
          onOnExit: onAlertMountChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalAlert, {
              title: _ctx.$t('createAPool.zeroWeightTitle'),
              type: "error"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('createAPool.zeroWeightInfo')), 1)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: Number(_unref(totalWeight)) > 100 || Number(_unref(totalWeight)) <= 0,
          unmountInstantly: "",
          onOnPresence: onAlertMountChange,
          onOnExit: onAlertMountChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalAlert, {
              title: _ctx.$t('createAPool.totalWeightAlertTitle'),
              type: "error"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('createAPool.totalWeightAlert', [_unref(zeroWeightToken)?.symbol])), 1)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(AnimatePresence, {
          isVisible: _unref(hasInjectedToken) && !_unref(acceptedCustomTokenDisclaimer),
          unmountInstantly: "",
          onOnPresence: onAlertMountChange,
          onOnExit: onAlertMountChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalAlert, {
              title: _ctx.$t('tokenWarningTitle'),
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalStack, {
                  vertical: "",
                  spacing: "xs"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('tokenWarning')), 1),
                    _createElementVNode("div", null, [
                      _createVNode(_component_BalBtn, {
                        size: "xs",
                        onClick: _unref(acceptCustomTokenDisclaimer)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('accept')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["isVisible"]),
        _createVNode(_component_BalBtn, {
          block: "",
          color: "gradient",
          disabled: _unref(isProceedDisabled),
          onClick: handleProceed
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(walletLabel)), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 512))
}
}

})