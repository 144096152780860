import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-sm" }

import usePoolCreation from '@/composables/pools/usePoolCreation';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { Pool } from '@/services/pool/types';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { similarPools } = usePoolCreation();
const { getToken } = useTokens();
const { fNum2 } = useNumbers();
/**
 * FUNCTIONS
 */
function getPoolLabel(pool: Pool) {
  const tokensString = pool.tokens
    .map(
      t =>
        `${getToken(t.address)?.symbol} ${fNum2(t.weight, FNumFormats.percent)}`
    )
    .join(', ');
  return `${tokensString} (${fNum2(pool.swapFee, FNumFormats.percent)} fee)`;
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalStack, {
        spacing: "sm",
        align: "center",
        horizontal: "",
        class: "border-b p-2 px-3 text-orange-500 dark:border-gray-600"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BalIcon, {
            class: "mt-1",
            name: "alert-circle",
            size: "md"
          }),
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('createAPool.similarPoolsExist')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BalStack, {
        vertical: "",
        spacing: "sm",
        class: "p-4"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(similarPools), (pool) => {
            return (_openBlock(), _createBlock(_component_BalLink, {
              key: `similarpool-${pool.id}`,
              target: "_blank",
              href: `/#/pool/${pool.id}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(getPoolLabel(pool)), 1)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})