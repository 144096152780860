import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  shadow: "xl",
  noBorder: ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "font-medium create-text" }
const _hoisted_4 = { class: "pl-2 text-sm optimize-text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "rounded-lg p-3" }
const _hoisted_8 = { class: "total-text" }
const _hoisted_9 = { class: "text-sm available-text" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "total-text" }

import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import usePoolCreation from '@/composables/pools/usePoolCreation';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum, isSameAddress } from '@/lib/utils';
import { isGreaterThan } from '@/lib/utils/validations';
import useWeb3 from '@/services/web3/useWeb3';

import CreateIcon from './create_pool.svg';
import questionIcon from './question-mark.svg';


export default _defineComponent({
  emits: ['update:height'],
  setup(__props, { emit }) {



/**
 * STATE
 */
const isOptimised = ref(false);
const cardWrapper = ref<HTMLElement>();

/**
 * COMPOSBALES
 */
const { userNetworkConfig } = useWeb3();
const {
  balanceFor,
  priceFor,
  nativeAsset,
  wrappedNativeAsset,
  dynamicDataLoading,
} = useTokens();
const { fNum2 } = useNumbers();
const {
  seedTokens,
  tokensList,
  totalLiquidity,
  scaledLiquidity,
  manuallySetToken,
  autoOptimiseBalances,
  currentLiquidity,
  isWethPool,
  useNativeAsset,
  poolLiquidity,
  createPoolTxHash,
  getOptimisedLiquidity,
  goBack,
  updateManuallySetToken,
  proceed,
  clearAmounts,
  setAmountsToMaxBalances,
  saveState,
} = usePoolCreation();
const { t } = useI18n();

const tokenAddresses = ref([] as string[]);

/**
 * COMPUTED
 */
const areAmountsMaxed = computed(() => {
  const isMaxed = seedTokens.value.every(t =>
    bnum(t.amount).eq(balanceFor(t.tokenAddress))
  );
  return isMaxed;
});

const isExceedingWalletBalance = computed(() => {
  // need to perform rounding here as JS cuts off those
  // really long numbers which makes it impossible to compare
  const isExceeding = tokenAddresses.value.some((t, i) =>
    bnum(seedTokens.value[i].amount).gt(balanceFor(t))
  );
  return isExceeding;
});

const arbitrageDelta = computed(() => {
  let totalPctDelta = bnum(0);
  for (const token of seedTokens.value) {
    const initialPct = bnum(token.amount)
      .times(priceFor(token.tokenAddress))
      .div(poolLiquidity.value);
    const expectedPct = token.weight / 100;
    const delta = initialPct.minus(expectedPct).abs();
    totalPctDelta = totalPctDelta.plus(delta);
  }
  return {
    delta: totalPctDelta,
    value: totalPctDelta.times(poolLiquidity.value),
  };
});

const hasZeroAmount = computed(() => {
  return seedTokens.value.some(seedToken => bnum(seedToken.amount).eq(0));
});

/**
 * LIFECYCLE
 */
onBeforeMount(() => {
  tokenAddresses.value = [...tokensList.value];
  if (isWethPool.value) setNativeAssetIfRequired();
});

onMounted(() => {
  // these functions need access to pricing data
  // do not attempt to optimise if there is no data
  if (!dynamicDataLoading.value) {
    optimiseLiquidity();
    scaleLiquidity();
  }
});

// these functions need access to pricing data
// do not attempt to optimise if there is no data
// but once we have that data, optimise away
watch(dynamicDataLoading, () => {
  if (!dynamicDataLoading.value) {
    setNativeAssetIfRequired();
    optimiseLiquidity();
    scaleLiquidity();
  }
});

/**
 * METHODS
 */
function optimiseLiquidity(force = false) {
  if (manuallySetToken.value && !force) return;
  isOptimised.value = true;

  const optimisedLiquidity = getOptimisedLiquidity();
  for (const token of seedTokens.value) {
    token.amount = optimisedLiquidity[token.tokenAddress].balanceRequired;
  }
}

function scaleLiquidity() {
  if (!autoOptimiseBalances.value || !manuallySetToken.value) return;

  for (const token of seedTokens.value) {
    if (token.tokenAddress !== manuallySetToken.value) {
      token.amount = scaledLiquidity.value[token.tokenAddress].balanceRequired;
    }
  }
}

function toggleAutoOptimise() {
  autoOptimiseBalances.value = !autoOptimiseBalances.value;
  checkLiquidityScaling();
}

function checkLiquidityScaling() {
  if (!autoOptimiseBalances.value) return;

  scaleLiquidity();
}

function handleMax() {
  setAmountsToMaxBalances();
  isOptimised.value = false;
}

function handleAmountChange(tokenAddress) {
  updateManuallySetToken(tokenAddress);
  checkLiquidityScaling();
  isOptimised.value = false;
}

function handleAddressChange(newAddress: string): void {
  useNativeAsset.value = isSameAddress(newAddress, nativeAsset.address);
}

function tokenOptions(index: number): string[] {
  if (
    isSameAddress(tokenAddresses.value[index], wrappedNativeAsset.value.address)
  )
    return [wrappedNativeAsset.value.address, nativeAsset.address];
  if (isSameAddress(tokenAddresses.value[index], nativeAsset.address))
    return [nativeAsset.address, wrappedNativeAsset.value.address];
  return [];
}

// If useNativeAsset is set, or ETH has a higher balance than WETH, then use it for the input.
function setNativeAssetIfRequired(): void {
  const nativeAssetBalance = balanceFor(nativeAsset.address);
  const wrappedNativeAssetBalance = balanceFor(
    wrappedNativeAsset.value.address
  );

  if (
    useNativeAsset.value ||
    bnum(nativeAssetBalance).gt(wrappedNativeAssetBalance)
  ) {
    // the native asset flag may not be set
    useNativeAsset.value = true;
    tokenAddresses.value = tokenAddresses.value.map(address => {
      if (isSameAddress(address, wrappedNativeAsset.value.address)) {
        return nativeAsset.address;
      }
      return address;
    });
  }
}

function handleClearAll() {
  clearAmounts();
  isOptimised.value = false;
}

function onAlertMountChange() {
  emit('update:height', {
    height: cardWrapper.value?.offsetHeight || 0,
  });
}

function saveAndProceed() {
  saveState();
  proceed();
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalToggle = _resolveComponent("BalToggle")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", { ref: cardWrapper }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BalStack, { vertical: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_BalStack, {
            vertical: "",
            spacing: "xs"
          }, {
            default: _withCtx(() => [
              _createVNode(AnimatePresence, {
                isVisible: isOptimised.value,
                unmountInstantly: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalStack, {
                    horizontal: "",
                    align: "center",
                    spacing: "sm",
                    class: "mt-2 rounded-lg"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", { src: _unref(CreateIcon) }, null, 8, _hoisted_2),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('optimizedPrefilled')), 1),
                      _createElementVNode("button", {
                        class: "text-sm font-medium click-text",
                        onClick: handleClearAll
                      }, " Clear all ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["isVisible"])
            ]),
            _: 1
          }),
          _createVNode(_component_BalStack, { vertical: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tokenAddresses.value, (address, i) => {
                return (_openBlock(), _createBlock(TokenInput, {
                  key: i,
                  amount: _unref(seedTokens)[i].amount,
                  "onUpdate:amount": [($event: any) => (_unref(seedTokens)[i].amount = $event), ($event: any) => (handleAmountChange(address))],
                  address: tokenAddresses.value[i],
                  "onUpdate:address": [
                    ($event: any) => (tokenAddresses.value[i] = $event),
                    _cache[0] || (_cache[0] = ($event: any) => (handleAddressChange($event)))
                  ],
                  fixedToken: "",
                  weight: _unref(seedTokens)[i].weight / 100,
                  name: `initial-token-${_unref(seedTokens)[i].tokenAddress}`,
                  options: tokenOptions(i),
                  rules: [_unref(isGreaterThan)(0)]
                }, null, 8, ["amount", "onUpdate:amount", "address", "onUpdate:address", "weight", "name", "options", "rules"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_BalStack, {
            horizontal: "",
            spacing: "sm",
            align: "center",
            class: "my-[14px]"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('autoOptimiseLiquidityToggle.label')), 1),
                _createVNode(_component_BalTooltip, { width: "64" }, {
                  activator: _withCtx(() => [
                    _createElementVNode("img", { src: _unref(questionIcon) }, null, 8, _hoisted_5)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _unref(t)('autoOptimiseLiquidityToggle.tooltip')
                    }, null, 8, _hoisted_6)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_BalToggle, {
                  name: "autoOptimise",
                  checked: _unref(autoOptimiseBalances),
                  onToggle: toggleAutoOptimise
                }, null, 8, ["checked"])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BalStack, {
              horizontal: "",
              justify: "between"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalStack, {
                  vertical: "",
                  spacing: "none"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_8, _toDisplayString(_unref(t)('total')), 1),
                    _createVNode(_component_BalStack, {
                      horizontal: "",
                      spacing: "xs",
                      class: "font-medium"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('available')) + ": " + _toDisplayString(_unref(fNum2)(_unref(totalLiquidity).toString(), _unref(FNumFormats).fiat)), 1),
                        _createElementVNode("button", {
                          disabled: _unref(areAmountsMaxed),
                          class: _normalizeClass([['font-semibold3 text-sm'], "max-text"]),
                          onClick: handleMax
                        }, _toDisplayString(_unref(areAmountsMaxed) ? _unref(t)('maxed') : _unref(t)('max')), 9, _hoisted_10)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_BalStack, {
                  vertical: "",
                  spacing: "none"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_11, _toDisplayString(_unref(fNum2)(_unref(currentLiquidity).toString(), _unref(FNumFormats).fiat)), 1),
                    _createVNode(AnimatePresence, {
                      isVisible: !isOptimised.value,
                      unmountInstantly: "",
                      onOnPresence: onAlertMountChange,
                      onOnExit: onAlertMountChange
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", {
                          class: "bg-gradient-to-tr from-blue-500 to-pink-500 bg-clip-text text-sm font-medium text-transparent hover:from-blue-800 hover:to-pink-800",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (optimiseLiquidity(true)))
                        }, _toDisplayString(_unref(t)('optimize')), 1)
                      ]),
                      _: 1
                    }, 8, ["isVisible"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(AnimatePresence, {
            isVisible: _unref(arbitrageDelta).delta > 0.05,
            unmountInstantly: "",
            onOnPresence: onAlertMountChange,
            onOnExit: onAlertMountChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalAlert, {
                type: "warning",
                title: 
              _unref(t)('createAPool.arbTitle', [
                _unref(fNum2)(_unref(arbitrageDelta).value.toString(), _unref(FNumFormats).fiat),
                _unref(fNum2)(_unref(arbitrageDelta).delta.toString(), _unref(FNumFormats).percent),
              ])
            
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('createAPool.arbReason')), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          }, 8, ["isVisible"]),
          _createVNode(_component_BalBtn, {
            disabled: _unref(isExceedingWalletBalance) || _unref(hasZeroAmount),
            block: "",
            color: "gradient",
            onClick: saveAndProceed
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('preview')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ])
  ], 512))
}
}

})