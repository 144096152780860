import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-4 flex items-center justify-between text-sm text-gray-400 dark:text-gray-600" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2" }

import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import BalActionSteps from '@/components/_global/BalActionSteps/BalActionSteps.vue';
import usePoolCreation from '@/composables/pools/usePoolCreation';
import useConfig from '@/composables/useConfig';
import useEthers from '@/composables/useEthers';
import useTokenApprovalActions from '@/composables/useTokenApprovalActions';
import useWeb3 from '@/services/web3/useWeb3';
import { TransactionActionInfo } from '@/types/transactions';

/**
 * TYPES
 */
type Props = {
  tokenAddresses: string[];
  amounts: string[];
  createDisabled: boolean;
  errorMessage: boolean;
};

type CreateState = {
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
  isRestoredTxConfirmed?: boolean;
  isLoadingRestoredTx: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokenAddresses: { type: Array, required: true },
    amounts: { type: Array, required: true },
    createDisabled: { type: Boolean, required: true },
    errorMessage: { type: Boolean, required: true }
  } as unknown as undefined,
  emits: ["success"] as unknown as undefined,
  setup(__props: {
  tokenAddresses: string[];
  amounts: string[];
  createDisabled: boolean;
  errorMessage: boolean;
}, { emit }: { emit: ({
  (e: 'success'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const createState = reactive<CreateState>({
  confirmed: false,
  confirmedAt: '',
  isRestoredTxConfirmed: false,
  isLoadingRestoredTx: false,
});

/*
 * COMPOSABLES
 */
// const route = useRoute();
const { t } = useI18n();
const { explorerLinks } = useWeb3();
const { networkConfig } = useConfig();
const { isTxConfirmed } = useEthers();
const { tokenApprovalActions } = useTokenApprovalActions(
  props.tokenAddresses,
  ref(props.amounts)
);
const {
  createPool,
  joinPool,
  poolId,
  poolTypeString,
  hasRestoredFromSavedState,
  needsSeeding,
  createPoolTxHash,
} = usePoolCreation();

/**
 * COMPUTED
 */
const actions = computed((): TransactionActionInfo[] => [
  ...tokenApprovalActions,
  {
    label: t('createPool'),
    loadingLabel: t('investment.preview.loadingLabel.create'),
    confirmingLabel: t('confirming'),
    action: createPool,
    stepTooltip: t('createPoolTooltip', [poolTypeString.value]),
  },
  {
    label: t('fundPool'),
    loadingLabel: t('investment.preview.loadingLabel.fund'),
    confirmingLabel: t('confirming'),
    action: joinPool,
    stepTooltip: t('fundPoolTooltip'),
  },
]);

const requiredActions = computed(() => {
  if (
    (hasRestoredFromSavedState.value && needsSeeding.value) ||
    createState.isRestoredTxConfirmed
  ) {
    return actions.value.filter(action => action.label === t('fundPool'));
  }
  return actions.value;
});

const explorerLink = computed((): string =>
  createState.receipt
    ? explorerLinks.txLink(createState.receipt.transactionHash)
    : ''
);

onBeforeMount(async () => {
  if (createPoolTxHash.value) {
    createState.isLoadingRestoredTx = true;
    const isConfirmed = await isTxConfirmed(createPoolTxHash.value);
    createState.isLoadingRestoredTx = false;
    createState.isRestoredTxConfirmed = isConfirmed;
  }
});

/**
 * METHODS
 */
function handleSuccess(details: any): void {
  createState.confirmed = true;
  createState.receipt = details.receipt;
  createState.confirmedAt = details.confirmedAt;
  emit('success');
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BalActionSteps, {
      actions: _unref(requiredActions),
      disabled: props.createDisabled,
      errorMessage: props.errorMessage,
      isLoading: _unref(createState).isLoadingRestoredTx,
      loadingLabel: _ctx.$t('restoring'),
      onSuccess: handleSuccess
    }, null, 8, ["actions", "disabled", "errorMessage", "isLoading", "loadingLabel"]),
    (_unref(createState).confirmed)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BalIcon, { name: "clock" }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(createState).confirmedAt), 1)
            ]),
            _createVNode(_component_BalLink, {
              href: _unref(explorerLink),
              external: "",
              noStyle: "",
              class: "group flex items-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm",
                  class: "ml-px transition-colors group-hover:text-pink-500"
                })
              ]),
              _: 1
            }, 8, ["href"])
          ]),
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'pool', params: { id: _unref(poolId) } },
            color: "gray",
            outline: "",
            block: "",
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('viewPool')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})