import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-semibold" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import usePoolCreation from '@/composables/pools/usePoolCreation';
import useTokens from '@/composables/useTokens';
import { FiatCurrency } from '@/constants/currency';
import { bnum, formatWordListAsSentence, isSameAddress } from '@/lib/utils';
import { isLessThanOrEqualTo } from '@/lib/utils/validations';
import { TokenPrices } from '@/services/coingecko/api/price.service';

type Props = {
  isVisible: boolean;
  unknownTokens: string[];
};


export default _defineComponent({
  props: {
    isVisible: { type: Boolean, required: true, default: false },
    unknownTokens: { type: Array, required: true, default: () => [] }
  } as unknown as undefined,
  emits: ['close'],
  setup(__props: {
  isVisible: boolean;
  unknownTokens: string[];
}, { emit }) {

const props = __props



// Hundred million max price for a token
const PRICE_CAP = 100000000;

/**
 * STATE
 */

/**
 * COMPOSABLES
 */
const { seedTokens } = usePoolCreation();
const { getToken, injectPrices, injectedPrices } = useTokens();
const { t } = useI18n();

/**
 * LIFECYCLE
 */
const unknownTokenPrices = computed((): TokenPrices => {
  const _unknownTokenPrices = {};
  for (const token of props.unknownTokens) {
    _unknownTokenPrices[token] = {
      [FiatCurrency.usd]:
        injectedPrices.value?.[token]?.[FiatCurrency.usd] || null,
    };
  }
  return _unknownTokenPrices;
});

/**
 * COMPUTED
 */
const readableUnknownTokenSymbols = computed(() => {
  const tokenSymbols = (props.unknownTokens || []).map(
    tokenAddress => getToken(tokenAddress).symbol
  );
  return formatWordListAsSentence(tokenSymbols, t);
});

const isSubmitDisabled = computed(() => {
  const noPricesEntered = props.unknownTokens.some(token =>
    [null, ''].includes(unknownTokenPrices[token])
  );
  const hasLargePrice = props.unknownTokens.some(token =>
    bnum(unknownTokenPrices?.[token]?.[FiatCurrency.usd] || '0').gt(PRICE_CAP)
  );
  return noPricesEntered || hasLargePrice;
});

/**
 * METHODS
 */
function getIndexOfUnknownToken(address: string) {
  return seedTokens.value.findIndex(token =>
    isSameAddress(address, token.tokenAddress)
  );
}

function injectUnknownPrices() {
  injectPrices(unknownTokenPrices.value);
  emit('close');
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    title: _unref(t)('unknownTokenPrice'),
    show: __props.isVisible,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalStack, {
        vertical: "",
        isDynamic: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('createAPool.unknownTokenPriceWarning', [
            _unref(readableUnknownTokenSymbols),
          ])), 1),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('createAPool.enterTokenPrice', [_unref(readableUnknownTokenSymbols)])), 1),
          _createVNode(_component_BalStack, {
            isDynamic: "",
            vertical: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.unknownTokens, (address, i) => {
                return (_openBlock(), _createBlock(TokenInput, {
                  key: i,
                  amount: _unref(unknownTokenPrices)[address][_unref(FiatCurrency).usd],
                  "onUpdate:amount": ($event: any) => (_unref(unknownTokenPrices)[address][_unref(FiatCurrency).usd] = $event),
                  fixedToken: "",
                  placeholder: "$0.00",
                  address: address,
                  name: `initial-token-${
            _unref(seedTokens)[getIndexOfUnknownToken(address)].tokenAddress
          }`,
                  noMax: "",
                  hideFooter: "",
                  rules: [_unref(isLessThanOrEqualTo)(PRICE_CAP)],
                  ignoreWalletBalance: ""
                }, null, 8, ["amount", "onUpdate:amount", "address", "name", "rules"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_BalBtn, {
            disabled: _unref(isSubmitDisabled),
            onClick: injectUnknownPrices
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "show"]))
}
}

})